import { useEffect } from 'react'

import { useRouter } from 'next/router'

import withAuthenticationRequired from 'hocs/withAuthenticationRequired'
import usePermissions from 'hooks/permissions/usePermissions'
import Routes from 'types/enums/routes'

const HomePage = () => {
  const router = useRouter()

  const { hasRoutePermissions } = usePermissions()

  useEffect(() => {
    if (hasRoutePermissions(Routes.BOOKINGS)) {
      router.push(Routes.BOOKINGS)
    }
    // Only for Global RFPs demo.
    else if (hasRoutePermissions(Routes.RFPS)) router.push(Routes.RFPS)
    else if (hasRoutePermissions(Routes.ORGANIZATION_RFPS)) router.push(Routes.ORGANIZATION_RFPS)
    else router.push(Routes.SETTINGS)
  }, [router, hasRoutePermissions])

  return <></>
}

export default withAuthenticationRequired(HomePage)
